<template>
    <header class="main-header active-header" id="main-header">
        <div class="site-brand">
            <router-link to="/">
                <img v-if="isShowMenu" src="@/assets/icons/png/icon.png" alt="logo">
                <img v-else src="@/assets/icons/png/Logo_white.png" alt="logo">
            </router-link>
        </div>

        <div class="menu-toggle" @click="toggleSidebar()">
            {{ isShowMenu ? 'Close' : 'Menu' }}
            <button class="burger">
                <span></span>
                <span></span>
            </button>
        </div>
    </header>

    <MainMenuVue @closeSidebar="closeSidebar()" />
</template>

<script>
import MainMenuVue from './MainMenu.vue';
export default {
    name: 'mainHeader',
    components: {
        MainMenuVue
    },

    data() {
        return {
            isShowMenu: false,
            activeMenu: true,
        }
    },

    methods: {
        toggleSidebar() {
            if (this.activeMenu) {
                this.activeMenu = false;
                const menu = document.getElementById('main-menu');
                const header = document.getElementById('main-header');

                // main-header
                const body = document.getElementsByTagName('body')[0];
                const burger = document.querySelector(".burger");
                if (this.isShowMenu) {
                    menu.classList.add('notShow');
                    body.classList.remove('disable-scroll');
                    // Wait for the animation to complete before hiding the menu
                    setTimeout(() => {
                        menu.classList.remove('show');
                        header.classList.remove('noPaddingBottom');
                        menu.style.visibility = 'hidden';
                        menu.style.opacity = '0';
                        this.activeMenu = true;
                    }, 500); // Match this with your animation duration
                } else {
                    menu.style.visibility = 'visible';
                    menu.style.opacity = '1';
                    menu.classList.add('show');
                    header.classList.add('noPaddingBottom');
                    menu.classList.remove('notShow');
                    body.classList.add('disable-scroll');
                    setTimeout(() => {
                        this.activeMenu = true;
                    }, 600); // Match this with your animation duration
                }
                burger.classList.toggle("nav-open");
                this.isShowMenu = !this.isShowMenu;
            }

        },

        closeSidebar() {
            if (this.activeMenu) {
                this.activeMenu = false;
                const menu = document.getElementById('main-menu');
                const header = document.getElementById('main-header');

                // main-header
                const body = document.getElementsByTagName('body')[0];
                const burger = document.querySelector(".burger");
                menu.classList.add('notShow');
                body.classList.remove('disable-scroll');
                // Wait for the animation to complete before hiding the menu
                setTimeout(() => {
                    menu.classList.remove('show');
                    header.classList.remove('noPaddingBottom');
                    menu.style.visibility = 'hidden';
                    menu.style.opacity = '0';
                    this.activeMenu = true;
                }, 500); // Match this with your animation duration

                burger.classList.remove("nav-open");
                this.isShowMenu = false;
            }

        },

        updateScroll() {
            if (window.scrollY >= 400) {
                document.getElementById('main-header').classList.add('active-header');

            } else {
                document.getElementById('main-header').classList.remove('active-header');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import './MainHeader.scss';
</style>