<template>
    <footer class="main-footer">
        <div class="ref-footer" ref="footer">
            <div class="row">
                <div class="col-md-8 col-12">
                    <div class="site-brand">
                        <img src="@/assets/icons/png/icon.png" alt="logo">
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="footer-menu">
                        <ul>
                            <li>
                                <router-link to="/about-us">About</router-link>
                            </li>
                            <li>
                                <router-link to="/services">Services</router-link>
                            </li>
                            <li>
                                <router-link to="/work">Work</router-link>
                            </li>
                            <li>
                                <router-link to="/contact-us">Contact Us</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="footer-menu">
                        <ul>
                            <li>
                                <router-link to="/privacy">Privacy & Policy</router-link>
                            </li>
                            <li>
                                <router-link to="/terms">Terms & Conditions</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row social-row mt-4">
                <div class="col-md-8 col-12">
                    <div class="terms">
                        Copyright © 2023 QOO Studio. All rights reserved.
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <ul class="socials">
                        <li>
                            <a target="_blank" href="https://www.instagram.com/qoostudiovr/?hl=en">
                                <img src="@/assets/icons/svg/instagram.svg" alt="social">
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/qoostudiovr">
                                <img src="@/assets/icons/svg/facebook.svg" alt="social">
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.youtube.com/channel/UCVmMq643K9Ru0TE9F6EPsRg">
                                <img src="@/assets/icons/svg/scocial.svg" alt="social">
                            </a>
                        </li>
                        <li>
                            <a target="_blank"
                                href="https://ca.linkedin.com/company/qoostudio?original_referer=https%3A%2F%2Fqoostudio.com%2F">
                                <img src="@/assets/icons/svg/linkedin.svg" alt="social">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
    name: 'mainFooter',
    data() {
        return {
            parallaxSpeed: 0.5,
            show: false,
            scrollY: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
        const footerElement = this.$refs.footer;
        footerElement.style.transform = `translateY(${this.$refs.footer.offsetHeight/2}px)`;

    },
    onUnmounted() {
        window.removeEventListener('scroll', this.scrollHandler);
    },
    watch: {
        "$store.state.showVideo": {
            handler() {
                const scrollY = window.scrollY;
                this.show = !this.$store.state.showVideo
                if (!this.show) {
                    this.scrollY = 0
                } else {
                    this.scrollY = scrollY
                }
            }
        }
    },
    methods: {
        scrollHandler() {
            const footerElement = this.$refs.footer;
            if (footerElement) {
                const scrollY = window.scrollY;
                const windowHeight = window.innerHeight;
                const p = document.body.offsetHeight - (windowHeight + scrollY)
                setTimeout(() => {
                    if (this.show) {
                        if ((scrollY - this.scrollY) > 0) {
                            footerElement.style.transform = `translateY(${(p/2)}px)`;
                        } else {
                            footerElement.style.transform = `translateY(${(p/2)}px)`;
                        }
                        this.scrollY = scrollY
                    } else {
                        footerElement.style.transform = `translateY(${this.$refs.footer.offsetHeight/2}px)`;
                    }
                }, 10);
            }
        },
        changeSlide(action) {
            if (action == 'next') {
                this.$refs.PartnersCarousel.next();
            } else {
                this.$refs.PartnersCarousel.prev();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import './MainHeader.scss';

.ref-footer {
    transition: 1s ease all;
}
</style>