import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

function lazyLoad(view) {
      return () => import(`../views/${view}.vue`)
}

const routes = [
      {
            path: "/:catchAll(.*)",
            name: "404",
            meta: { layout: "full", title: "Page introuvable" },
            component: lazyLoad('404'),
      },
      {
            path: '/',
            name: 'homePage',
            component: lazyLoad('home/Home'),
            meta: { layout: "with-header", title: "Home", requiresAuth: false },
            props: true,
      },
      {
            path: '/work',
            name: 'WorkPage',
            component: lazyLoad('work/WorkPage'),
            meta: { layout: "with-header", title: "Work", requiresAuth: false },
            props: true,
      },
      {
            path: '/project/:id',
            name: 'ProjectPage',
            component: lazyLoad('projects/ProjectPage'),
            meta: { layout: "with-header", title: "Project", requiresAuth: false },
            props: true,
      },
      {
            path: '/blogs',
            name: 'BlogPage',
            component: lazyLoad('blog/BlogPage'),
            meta: { layout: "with-header", title: "Blog", requiresAuth: false },
            props: true,
      },
      {
            path: '/blog-detail/:id',
            name: 'blog-detail',
            component: lazyLoad('blog/BlogDetail'),
            meta: { layout: "with-header", title: "Blog Detail", requiresAuth: false },
            props: true,
      },
      {
            path: '/contact-us',
            name: 'contact',
            component: lazyLoad('contact-us/Contact'),
            meta: { layout: "with-header", title: "Contact Us", requiresAuth: false },
            props: true,
      },
      {
            path: '/services',
            name: 'services',
            component: lazyLoad('services/Services'),
            meta: { layout: "with-header", title: "Services", requiresAuth: false },
            props: true,
      },
      {
            path: '/about-us',
            name: 'about',
            component: lazyLoad('about/About'),
            meta: { layout: "with-header", title: "About", requiresAuth: false },
            props: true,
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      scrollBehavior(to) {
            if (to.hash) {
                  return { el: to.hash };
            }
            return { top: 0 };
      },
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((toRoute, fromRoute, next) => {
      window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
      if (toRoute.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            if (!store.getters.accessToken && !localStorage.getItem('accessToken') && !toRoute.query.token) {
                  next({ name: 'login' });
                  return; // Stop the execution here as we've already called next()
            }
      }
      next(); // go to wherever I'm going if not redirected to login
});

export default router;
