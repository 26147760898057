import App from './App.vue';
import router from "@/router";
import { createApp } from 'vue';
import mitt from 'mitt';
import store from "@/store";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import VueLazyload from 'vue-lazyload'

// const loadImage = require('./assets/loading.gif')
// const errorImage = require('./assets/error.gif')

const app = createApp(App);
app.use(VueLazyload, {
  preLoad: 1.3,
  error: '/img/dribbble.gif',
  loading: '/img/dribbble.gif',
  attempt: 1,
  listenEvents: ['scroll'],
})
app.component("v-select", vSelect);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(router);
app.use(store);

app.mount('#app');
