<template>
    <div id="qoostudio-main">
        <div class="main-page">

            <MainHeaderVue />

            <div class="router-outlet">
                <router-view></router-view>
            </div>

            <MainFooterVue ref="MainFooter" />

        </div>
    </div>

    <OverlayLoader v-show="isLoading" />
</template>

<script>

import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import MainHeaderVue from './components/common/MainHeader.vue';
import MainFooterVue from './components/common/MainFooter.vue';

export default {
    name: 'App',
    components: {
        OverlayLoader,
        MainFooterVue,
        MainHeaderVue
    },

    data() {
        return {
            isScrolled: true,
        }
    },

    computed: {
        isLoading: {
            get() {
                return this.$store.state.isLoading;
            }
        },
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        const routerOutlets = document.querySelector(".router-outlet")
        const footerEl = this.$refs.MainFooter.$el; // Direct reference to the DOM element.
        const footerHeight = footerEl.clientHeight; // Get the content's height.

        const computedStyle = window.getComputedStyle(footerEl);
        const paddingTop = parseInt(computedStyle.paddingTop, 10);
        const totalHeight = footerHeight + paddingTop;
        
        if(window.innerWidth > 900) {
            routerOutlets.style.marginBottom = footerHeight + 'px'
        } else {
            routerOutlets.style.marginBottom = totalHeight + 'px'

        }
    },

    watch: {
        "$store.state.showModal": {
            handler() {
                const routerOutlets = document.querySelector(".router-outlet")
                console.log(routerOutlets);
                if (this.$store.state.showModal) {
                    routerOutlets.style.position = 'static';
                } else {
                    routerOutlets.style.position = 'relative';
                }

            }
        }
    },

    updated() {
        this.handleScroll()
    },

    methods: {
        handleScroll() {
            const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
            this.isScrolled = scrollableHeight - window.scrollY > this.$refs.MainFooter.$el.clientHeight;
            if (!this.isScrolled && this.$refs.MainFooter.$el) {
                this.$refs.MainFooter.$el.style.zIndex = 3
                this.$store.commit('SET_SHOW_VIDEO', false);
            } else if (this.$refs.MainFooter.$el) {
                this.$refs.MainFooter.$el.style.zIndex = -1
                this.$store.commit('SET_SHOW_VIDEO', true);
            }
        },
    },

    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },

}

</script>

<style>
.page-opacity-enter-active,
.page-opacity-leave-active {
    transition: 0.5s ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
    opacity: 0;
}

.router-outlet {
    /* margin-bottom: 400px; */
    position: relative;
    z-index: 4;
}

.main-footer {
    position: fixed;
    bottom: .1px;
    width: 100%;
    z-index: 3;
}
</style>
