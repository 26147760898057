<template>
    <div class="main-menu" id="main-menu">

        <ul class="menu">
            <li @click="closeSidebar()">
                <router-link to="/">Home</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/services">Services</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/work">Work</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/blogs">Blogs</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/about-us">About</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/contact-us">Contact Us</router-link>
            </li>
            <li @click="closeSidebar()">
                <router-link to="/contact-us?careers=true">Careers</router-link>
            </li>
        </ul>

        <ul class="socials">
            <li>
                <a target="_blank" href="https://www.instagram.com/qoostudiovr/?hl=en">
                    <img src="@/assets/icons/svg/instagram.svg" alt="social">
                </a>
            </li>
            <li>
                <a target="_blank" href="https://www.facebook.com/qoostudiovr">
                    <img src="@/assets/icons/svg/facebook.svg" alt="social">
                </a>
            </li>
            <li>
                <a target="_blank" href="https://www.youtube.com/channel/UCVmMq643K9Ru0TE9F6EPsRg">
                    <img src="@/assets/icons/svg/scocial.svg" alt="social">
                </a>
            </li>
            <li>
                <a target="_blank"
                    href="https://ca.linkedin.com/company/qoostudio?original_referer=https%3A%2F%2Fqoostudio.com%2F">
                    <img src="@/assets/icons/svg/linkedin.svg" alt="social">
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'mainMenu',

        methods: {
            closeSidebar() {
                this.$emit('closeSidebar' , true);
            }
        },

        watch: {
            page: function() {
                this.closeSidebar();
            }
        },

        computed: {
            page () {
                return this.$route;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './MainHeader.scss';
</style>